<template>
    <div class="w-2/3 mx-auto my-4">
        <modal :isVisible="showMarkAsLost" @close="closeModals" :width="'w-2/5'" :outsideClick="false">
            <template>
                <mark-as-lost :itemData="getItemData"></mark-as-lost>
            </template>  
        </modal>

        <modal :isVisible="showMarkAs" @close="closeModals" :width="'w-2/5'" :outsideClick="false">
            <template>
                <mark-as :itemData="getItemData"></mark-as>
            </template>  
        </modal>


        <div v-if="showCard">
            <div class="flex space-x-2">
                <button class="rounded-t-lg px-2 py-1 font-sans font-medium"
                @click="window(1)"
                :class="{ 'bg-gray-200' : winlocation !== 1, 'bg-white' : winlocation === 1 }">
                    Information
                </button>
                <button class="rounded-t-lg px-2 py-1 font-sans font-medium"
                @click="window(2)"
                :class="{ 'bg-gray-200' : winlocation !== 2, 'bg-white' : winlocation === 2 }">
                    Attributes
                </button>
            </div>
            <div class="bg-white p-3 py-5 rounded-b-lg shadow-md">
                <!-- Window 1 -->
                <form v-if="winlocation === 1" class="flex flex-col mx-2">
                    <div v-if="isInUse" class="mb-4">
                        <span class="text-red-700 bg-red-200 p-2 rounded-md m-2">Some fields cannot be changed because the item is not available</span>
                    </div>
                    
                    <div class="flex flex-row">
                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="site">Site Location:</label>
                            <div class="inline relative">
                                <select name="site" id="site" v-model="site"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.site.$error ) && ( site === null ),
                                'border-blue-300': !((submitted && $v.site.$error) && ( site === null )) }">
                                    <option hidden value="null">Select a Site...</option>
                                    <option v-for="item in getLocations" :value="item" :key="item">{{item}}</option>
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.site.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>

                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="label">Label:</label>
                            <div class="inline relative">
                                <input name="label" id="label" v-model="label" placeholder="Write Here..."
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-blue-300 border-b-2 rounded-md h-7 my-1 mx-auto"
                                />    
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="serial_number">Serial Number:</label>
                            <div class="inline relative">
                                <input name="serial_number" id="serial_number" v-model="serial_number" pattern="[A-Za-z0-9]+" maxlength="32" placeholder="Serial Number..."
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.serial_number.$error) && ( serial_number === null ),
                                'border-blue-300': !((submitted && $v.serial_number.$error) && ( serial_number === null )) }"/>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="!$v.serial_number.minLength"
                                class="font-light text-sm text-red-700">
                                    Serial number must have at least {{ $v.serial_number.$params.minLength.min }} alphanumeric characters
                                </span>
                                <span v-if="submitted && !$v.serial_number.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>

                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="confirm_serial_number">Confirm Serial Number:</label>
                            <div class="inline relative">
                                <input name="confirm_serial_number" id="confirm_serial_number" v-model="confirm_serial_number" pattern="[A-Za-z0-9]+" maxlength="32" onpaste="return false;" ondrop="return false;" autocomplete="off" placeholder="Serial Number..."
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{
                                    'border-red-600': 
                                    (((confirm_serial_number === null || confirm_serial_number === '')) || 
                                    (!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN) || (!$v.confirm_serial_number.minLength)),
                                    'border-blue-300': 
                                    !(((confirm_serial_number === null || confirm_serial_number === '')) || 
                                    (!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN) || (!$v.confirm_serial_number.minLength)) }"/>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="!(confirm_serial_number === null) & !$v.confirm_serial_number.sameAsSN" 
                                class="text-sm font-light flex flex-row text-red-700">
                                    Serial numbers does not match!
                                </span>
                                <span v-if="(confirm_serial_number === null || confirm_serial_number === '')" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="use_type">Use Location:</label>
                            <div class="inline relative">
                                <select name="use_type" id="use_type" v-model="use_type" :disabled="isInUse"
                                class="w-full px-2 bg-transparent text-gray-400 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.use_type.$error) && ( use_type === null ),
                                'border-blue-300': !((submitted && $v.use_type.$error) && ( use_type === null )),
                                'cursor-not-allowed' : isInUse,
                                'hover:text-gray-700 focus:text-gray-700' : !isInUse }">
                                    <option value="on-site">On-Site</option>
                                    <option value="off-site">Off-Site</option>
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> Specify if this equipment will be used outside or within Centris facilities</span>
                                <span v-if="submitted && !$v.use_type.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>

                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="use">Use:</label>
                            <div class="inline relative">
                                <select name="use" id="use" v-model="use" :disabled="isInUse"
                                class="w-full px-2 bg-transparent text-gray-400 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.use.$error) && ( use === null ),
                                'border-blue-300': !((submitted && $v.use.$error) && ( use === null )),
                                'cursor-not-allowed' : isInUse,
                                'hover:text-gray-700 focus:text-gray-700' : !isInUse }">
                                    <option hidden value="null">Select a Use...</option>
                                    <optgroup label="Department">
                                        <option v-for="item in catalog_departments" :key="item" :value="item">{{item}}</option>
                                    </optgroup>
                                    <optgroup label="Campaign">
                                        <option v-for="item in catalog_campaigns" :key="item" :value="item">{{item}}</option>
                                    </optgroup>
                                    <optgroup  label="Warehouse">
                                        <option value="warehouse">Warehouse</option>
                                    </optgroup>
                                    <!-- <optgroup  label="Other">
                                        <option value="benefactor">Benefactor</option>
                                    </optgroup> -->
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.use.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="manufacturer">Manufacturer:</label>
                            <div class="inline relative">
                                <select name="manufacturer" id="manufacturer" v-model="manufacturer"
                                @change="refreshModels()"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.manufacturer.$error) && ( manufacturer === null ),
                                'border-blue-300': !((submitted && $v.manufacturer.$error) && ( manufacturer === null )) }">
                                    <option hidden value="null">Select a Manufacturer...</option>
                                    <option v-for="item in catalog_manufacturers" :key="item.Manufacturer_ID" :value="item.Manufacturer_ID">{{item.Manufacturer_Label}}</option>
                                    <option value="newManuf">New Manufacturer</option>
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.manufacturer.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>

                        <div v-if="manufacturer === 'newManuf'" class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="new_manufacturer">New Manufacturer:</label>
                            <div class="inline relative">
                                <input name="new_manufacturer" id="new_manufacturer" v-model="new_manufacturer" placeholder="Write Here..."
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && manufacturer === 'newManuf' && $v.new_manufacturer.$error) && ( new_manufacturer === null ),
                                'border-blue-300': !((submitted && manufacturer === 'newManuf' && $v.new_manufacturer.$error) && ( new_manufacturer === null )) }"/> 
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.new_manufacturer.required && manufacturer === 'newManuf'" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>

                        <div v-else class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="model">Model:</label>
                            <div class="inline relative">
                                <select name="model" id="model" v-model="model"
                                :disabled="manufacturer === null || catalog_models === []"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.model.$error) && ( model === null ),
                                'border-blue-300': !((submitted && $v.model.$error) && ( model === null )) }">
                                    <option v-if="manufacturer === null || catalog_models === []" hidden value="null">Select a Manufacturer First</option>
                                    <option v-else hidden value="null">Select a model...</option>
                                    <option v-for="item in catalog_models" :key="item.Model_ID" :value="item.Model_ID">{{item.Model_Label}}</option>
                                    <option value="newModl">New Model</option>
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.model.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div v-if="model === 'newModl' || manufacturer === 'newManuf'" class="w-1/2 text-left justify mx-2 mb-3">
                            <div class="text-left justify mx-2 mb-3">
                                <label for="model">New Model:</label>
                                <div class="inline relative">
                                    <input name="new_model" id="new_model" v-model="new_model" placeholder="Write Here..."
                                    class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                    :class="{'border-red-600': ((submitted && model === 'newModl' && $v.new_model.$error) || (submitted && manufacturer === 'newManuf' && $v.new_model.$error)) && ( new_model === null),
                                    'border-blue-300': !((submitted && model === 'newModl' && $v.new_model.$error) || (submitted && manufacturer === 'newManuf' && $v.new_model.$error)) && ( new_model === null) }"/>
                                    <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                    <span v-if="submitted && !$v.new_model.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="w-1/2 text-left justify mx-2 mb-3">
                            <label for="category">Category:</label>
                            <div class="inline relative">
                                <select name="category" id="category" v-model="category"
                                :disabled="catalog_types === []"
                                class="w-full px-2 bg-transparent text-gray-400 hover:text-gray-700 focus:text-gray-700 border-b-2 rounded-md h-7 my-1 mx-auto"
                                :class="{'border-red-600': (submitted && $v.category.$error) && ( category === null ),
                                'border-blue-300': !((submitted && $v.category.$error) && ( category === null )) }">
                                    <option hidden value="null">Select a Category...</option>
                                    <option v-for="item in catalog_types" :key="item.Item_Type_ID" :value="item.Item_Type_ID">{{item.Item_Type_Label}}</option>
                                </select>
                                <span class="text-sm font-light flex flex-row"><span class="text-red-700">*</span> This field is mandatory</span>
                                <span v-if="submitted && !$v.category.required" class="animate-ping absolute top-3 right-0 block h-1 w-1 rounded-full ring-2 ring-red-400 bg-red-600"></span>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="flex flex-row">
                        <button @click="addItem"
                        :disabled="loading"
                        class="bg-gray-700 px-3 py-2 rounded-sm shadow-faded text-white mt-2 mx-auto">
                            <span v-if="loading" class="uppercase font-sans font-semibold text-xs">
                                Saving  
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" /><path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                                </svg>
                            </span>
                            <span v-else class="uppercase font-sans font-semibold text-xs">
                                Edit Item
                            </span>
                        </button>
                    </div>

                    <div class="flex flex-row" v-if="!isLost">
                        <button @click="showMarkAsLostModal"
                        class="bg-red-700 px-3 py-2 rounded-sm shadow-faded text-white mt-4 ml-auto">
                            <span class="uppercase font-sans font-semibold text-xs">
                                Mark Item as lost
                            </span>
                        </button>
                    </div>

                    <div class="flex flex-row" v-else>
                        <button @click="showMarkAsModal"
                        class="bg-red-700 px-3 py-2 rounded-sm shadow-faded text-white mt-4 ml-auto">
                            <span class="uppercase font-sans font-semibold text-xs">
                                Recover Item
                            </span>
                        </button>
                    </div>
                </form>
                <!-- Window 2 -->
                <form v-if="winlocation === 2" class="flex flex-col mx-2">
                    <attributes :key="attributesKey" @toggle="restartWindow"></attributes>
                </form>
            </div>
        </div>

        <div v-else class="flex justify-content-center flex-col my-auto">
            We are loading the required information. Please wait
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce flex flex-row mx-auto mt-3" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
            </svg>
        </div>
    </div>
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import modal from '@/views/components/Modal/modalBase.vue'
import MarkAsLost from '@/views/inventory/modals/mark-as-lost.vue'
import MarkAs     from '@/views/inventory/modals/mark-as.vue'
import Attributes from '@/views/inventory/Forms/attributes.vue'

export default {
    components: { 
        modal,
        MarkAsLost,
        MarkAs,
        Attributes
    },
    data() {
       return{
           showCard: false,
           submitted: false,
           attributesKey: 0,
           loading: false,
           showMarkAsLost: false,
           showMarkAs: false,
           winlocation: 1,

           itemId: null,
           site: null,
           serial_number: null,
           confirm_serial_number: null,
           use_type: 'on-site',
           use: null,
           manufacturer: null,
           model: null,
           category: null,
           label: null,

           new_manufacturer: null,
           new_model: null,

           catalog_manufacturers: [],
           catalog_models: [],
           catalog_types: [],
           catalog_campaigns: [],
           catalog_departments: []
       } 
    },
    validations() {
        if(this.manufacturer === 'newManuf'){
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4)},
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                new_manufacturer: { required },
                new_model:      { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
        }if (this.model === 'newModl') {
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4) },
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                model:          { required },
                new_model:      { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
            
        }else {
            return {
                site:           { required },
                serial_number:  { required, minLength: minLength(4) },
                use_type:       { required },
                use:            { required },
                manufacturer:   { required },
                model:          { required },
                category:       { required },
                confirm_serial_number:       {  
                    minLength: minLength(4),
                    sameAsSN: sameAs('serial_number')
                }, 
            }
        }
  },
  methods: {
      ...mapActions(['fetchNewItemProperties', 'fetchSites', 'getInventoryItemInfo', 'editItemToInventory']),
        window:function(data){
            this.winlocation = data;
        },
        restartWindow:function(){
            this.attributesKey++;
        },
        closeModals: function(){
            this.showMarkAsLost =  false;
            this.showMarkAs = false;
        },
        showMarkAsLostModal:function(){
            event.preventDefault();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.showMarkAsLost =  true;
        },
        showMarkAsModal:function(){
            event.preventDefault();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.showMarkAs =  true;
        },
        addItem(){
        event.preventDefault();
        this.submitted = true;

        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        let addingType = null;
        if(this.new_manufacturer === null && this.new_model === null){
            addingType = 'normal'
        }else if(this.new_manufacturer === null && this.new_model !== null ){
            addingType = 'new_model'
        }else{
            addingType = 'new_manufacturer'
        }
        this.loading = true;
        let Obj = {
            id: this.itemId,
            site: this.site,
            serial_number: this.serial_number?.toUpperCase(),
            manufacturer: this.manufacturer,
            model: this.model,
            use_type: this.use_type,
            use: this.use,
            type: this.category,
            label: this.label === null ? this.serial_number : this.label,
            new_manufacturer: this.new_manufacturer,
            new_model: this.new_model,
            addingType: addingType
        };
        this.editItemToInventory(Obj).then(()=>{
            this.$fire({
                type:'success',
                title:'Item Edited Successfully!.',
                showConfirmButton:false,
                timer: 1500
            }).then(() => {
                this.$router.go(-1);
            })
            
        })
        .catch((error)=>{
            let message =  error.response.data.message ?? 'Something went wrong';
            let indexEnd = message.indexOf('(SQL:');
            let substringed = message.substring(0,indexEnd);
            this.$fire({
                type: 'warning',
                title: 'Something went wrong',
                text:`${substringed}`,
                showConfirmButton: true,                
            });
            this.loading = false;
        })
        },
        refreshModels(){
            this.type = null;
            this.new_type = null;
            this.model = null;
            this.new_model = null;
            this.new_manufacturer = null;
            this.catalog_models = this.getNewItemsProperties.models.filter(model => model.Manufacturer_ID === this.manufacturer);
        },
        clearVariables(){
            this.submitted = false;
            this.loading = false;
            this.site = null;
            this.serial_number = null;
            this.use = null;
            this.confirm_serial_number = null;
            this.manufacturer = null;
            this.model = null;
            this.label = null;
            this.new_manufacturer = null;
            this.new_model = null;
        }
    },
    created(){
        this.itemId = this.$route.params.id;
        this.fetchNewItemProperties().then(() => {
            this.catalog_manufacturers = this.getNewItemsProperties.manufacturers;
            this.catalog_types = this.getNewItemsProperties.types;
            this.catalog_campaigns = this.getNewItemsProperties.campaigns;
            this.catalog_departments = this.getNewItemsProperties.departments;
        })
        this.getInventoryItemInfo(this.itemId).then(() => {
            this.site = this.getItemData?.Site;
            this.serial_number = this.getItemData?.Serial_Number;
            this.use = this.getItemData?.Used_By;
            this.label = this.getItemData?.Label;
            this.category = this.getItemData?.Item_type;
            this.manufacturer = parseInt(this.getItemData?.Manufacturer);
            this.use_type = this.getItemData?.Use_Type;
            this.refreshModels();
            this.model = this.getItemData?.Model;
            this.showCard = true;
        }).catch(() => {
            this.$fire({
                type: 'error',
                title: 'Something went wrong, you will be redirected to the previouse page',
                showConfirmButton: true
            }).then(()=>{
                this.$router.go(-1);
            })
        })
        this.fetchSites();
    },
    watch: {
        serial_number(value) {
            if (this.serial_number === null) return;
            this.serial_number = value?.toUpperCase().replace(/\s/gi && /[^a-zA-Z0-9]+/gi, '');
        },
        confirm_serial_number(value) {
            if (this.confirm_serial_number === null) return;
            this.confirm_serial_number = value?.toUpperCase().replace(/\s/gi && /[^a-zA-Z0-9]+/gi, '');
        }
    },
    computed:{
        ...mapGetters(['getNewItemsProperties', 'getLocations', 'getItemData']),
        isInUse(){
            return this.getItemData?.Status.toLowerCase() === "in-use"
        },
        isLost(){
            return this.getItemData?.Status.toLowerCase() === "lost"
        }
    },
    mounted(){
        let title = 'Inventory: Edit Item';
        this.$emit('set-title',title);
    },
}
</script>
<style scoped>
#serial_number, #confirm_serial_number { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
</style>