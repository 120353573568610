import { render, staticRenderFns } from "./maintenanceFormPage.vue?vue&type=template&id=90ec889e&scoped=true&class=w-full%20h-full"
import script from "./maintenanceFormPage.vue?vue&type=script&lang=js"
export * from "./maintenanceFormPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ec889e",
  null
  
)

export default component.exports