<template>
    <div class="flex space-x-5">
        <modal @toggle="confirmation" :employee="employee" :data="modal_information" v-if="show_modal"/>
        <div class="flex-1 text-left bg-white rounded-[10px] shadow-sm p-10 max-w-[650px]">
            <profile-information class="m-auto" v-if="loadData" :isTermination="true" />
            <div class="space-y-7 text-center">
                <div class="inline-block bg-gray-50 rounded-full justify-center items-center shadow-sm p-2">
                    <button class="relative rounded-full px-3 leading-[2.5]" :class="{'bg-blue-100 text-blue-700' : windowCurrent === 'inventory'}" @click="changeWindow('inventory')"><i class="bi bi-box2"></i> Inventory <span v-if="getInventoryEmployee.length" class="inline-block rounded-full text-sm bg-red-500 text-white text-center leading-[1.5] px-2 ml-1 align-middle">{{ getInventoryEmployee.length }}</span></button>
                    <button class="relative rounded-full px-3 leading-[2.5]" :class="{'bg-blue-100 text-blue-700' : windowCurrent === 'msl'}" @click="changeWindow('msl')"><i class="bi bi-heart-pulse"></i> MSL <span v-if="getMsl.length" class="inline-block rounded-full text-sm bg-red-500 text-white text-center leading-[1.5] px-2 ml-1 align-middle">{{ getMsl.length }}</span></button>
                    <button class="relative rounded-full px-3 leading-[2.5]" :class="{'bg-blue-100 text-blue-700' : windowCurrent === 'hr-inventory'}" @click="changeWindow('hr-inventory')"><i class="bi bi-person-check"></i> HR Inventory <span v-if="getItemsEmployee.length" class="inline-block rounded-full text-sm bg-red-500 text-white text-center leading-[1.5] px-2 ml-1 align-middle">{{ getItemsEmployee.length }}</span></button>
                    <button class="relative rounded-full px-3 leading-[2.5]" :class="{'bg-blue-100 text-blue-700' : windowCurrent === 'comments'}" @click="changeWindow('comments')" v-if="!!getDetailsTermination"><i class="bi bi-sticky"></i> Comments <span v-if="getCommentsTermination.length" class="inline-block rounded-full text-sm bg-red-500 text-white text-center leading-[1.5] px-2 ml-1 align-middle">{{ getCommentsTermination.length }}</span></button>
                </div>
                <div>
                    <div label="Inventory" v-if="windowCurrent === 'inventory'">
                        <div v-if="getInventoryEmployee.length">
                            <div class="space-y-2">
                                <div class="bg-gray-100 rounded-lg p-3" v-for="inventory in getInventoryEmployee" :key="inventory.Catalog_ID">
                                    <h5 class="flex justify-between text-lg mb-2">{{ inventory.Label }}
                                        <span v-if="inventory.Status=='IN-USE'" class="leading-1 text-[70%] bg-green-600 text-white rounded-full px-3 py-0">{{ inventory.Status }}</span>
                                        <span v-else-if="inventory.Status=='AVAILABLE'" class="leading-1 text-[70%] bg-green-600 text-white rounded-full px-3 py-0">{{ inventory.Status }}</span>
                                        <span v-else-if="inventory.Status=='LOST'" class="leading-1 text-[70%] bg-red-600 text-white rounded-full px-3 py-0">{{ inventory.Status }}</span>
                                    </h5>
                                    <p v-if="inventory.Item_Type_Label">{{ inventory.Item_Type_Label }}</p>
                                    <div class="flex justify-between text-sm leading-[1.2]">
                                        <div><b>SN:</b> {{ inventory.Serial_Number }}</div>
                                        <div><b>Model:</b> {{ inventory.Model }}</div>
                                    </div>
                                    <div class="text-left text-sm leading-[1.2]"><b>Manufacturer:</b> {{ inventory.Manufacturer }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-gray-300 italic text-center">0 items</p>
                        </div>
                    </div>
                    <div label="MSL" v-if="windowCurrent === 'msl'">
                        <div v-if="getMsl.length">
                            <div class="space-y-2">
                                <div class="bg-gray-100 rounded-lg p-3" v-for="msl in getMsl" :key="msl.Catalog_ID">
                                    <h5 class="flex justify-between text-lg mb-2">{{ msl.classification }} ({{ msl.periodicity }})
                                        <span v-if="msl.status=='pending'" class="leading-1 text-[70%] bg-amber-500 text-white rounded-full px-3 py-0 uppercase">{{ msl.status }}</span>
                                        <span v-if="msl.status=='approved'" class="leading-1 text-[70%] bg-green-600 text-white rounded-full px-3 py-0 uppercase">{{ msl.status }}</span>
                                        <span v-if="msl.status=='declined'" class="leading-1 text-[70%] bg-red-600 text-white rounded-full px-3 py-0 uppercase">{{ msl.status }}</span>
                                    </h5>
                                    <p v-if="msl.Item_Type_Label">{{ msl.Item_Type_Label }}</p>
                                    <div class="flex justify-between text-sm leading-[1.2]">
                                        <div><b>Start Date:</b> {{ msl.start_date }}</div>
                                        <div><b>Days Duration:</b> {{ msl.days_duration }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-gray-300 italic text-center">0 items</p>
                        </div>
                    </div>
                    <div label="HR Inventory" v-if="windowCurrent === 'hr-inventory'">
                        <div v-if="getItemsEmployee && getItemsEmployee.length">
                            <div class="space-y-2">
                                <div class="bg-gray-100 rounded-lg p-3" v-for="item in getItemsEmployee" :key="item.ID">
                                    <h5 class="flex justify-between text-lg mb-2">{{ item.Label }}
                                        <span v-if="item.Status=='OPEN'" class="leading-1 text-[70%] bg-green-600 text-white rounded-full px-3 py-0">{{ item.Status }}</span>
                                        <span v-else-if="item.Status=='CLOSED'" class="leading-1 text-[70%] bg-red-600 text-white rounded-full px-3 py-0">{{ item.Status }}</span>
                                    </h5>
                                    <p v-if="item.Description" class="text-left text-sm leading-none">{{ item.Description }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-gray-300 italic text-center">0 items</p>
                        </div>
                    </div>
                    <div label="Comments" v-if="windowCurrent === 'comments'">
                        <div class="flex justify-end">
                            <button class="space-x-2 rounded-full px-3 leading-[2.5] bg-orange-900 text-white mb-4 uppercase text-sm font-semibold" @click.prevent="showModal">
                                <i class="bi bi-pencil-fill"></i> Write new
                            </button>
                        </div>
                        <termination-comments :key="commentsKey" :termination_id="this.information.id" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1 text-left bg-white rounded-[10px] shadow-sm p-10">
            <h3 class="text-2xl text-center">Termination</h3>
            <div class="steps-termination flex items-center justify-between mb-6" v-if="getStepper.length">
                <div v-for="step in getStepper" :key="step.ID" class="flex items-center py-2 px-3 rounded-xl bg-gradient-to-r to-white"
                :class="getDetailsTermination && getDetailsTermination.order==step.order && getDetailsTermination.Status=='pending' || !getDetailsTermination && step.order==1 ? 'text-amber-600 from-amber-50':(getDetailsTermination && step.order<=getDetailsTermination.order || getDetailsTermination && getDetailsTermination.Status=='completed' ? 'text-green-700 from-green-50':(getDetailsTermination && getDetailsTermination.Status=='cancelled' ? 'text-red-700 from-red-50':'text-gray-900 from-gray-50'))">
                    <span class="text-5xl opacity-10 font-bold tracking-tighter">{{ step.order }}</span>
                    <div class="flex items-center -ml-1">
                        <i class="bi bi-circle-fill"></i>
                        <span class="text-sm leading-none ml-1">{{ step.Step }}</span>
                    </div>
                </div>
            </div>
            <div v-if="getDetailsTermination && getDetailsTermination.Status === 'completed'">
                <div class="border rounded-2xl overflow-hidden">
                    <div class="relative bg-green-600 text-white text-center p-4">
                        <i class="absolute left-[5%] -top-7 bi bi-check-circle text-9xl opacity-20"></i> Termination Completed
                    </div>
                    <div class="p-4 space-y-4">
                        <div class="flex">
                            <div class="flex-1 flex flex-col text-center">
                                <i class="text-3xl bi" :class="getDetailsTermination.Voluntary=='Voluntary' ? 'bi-check-circle-fill text-green-600':'bi-x-circle-fill text-red-600'"></i>                            
                                <label class="text-xs mb-1 uppercase">{{ getDetailsTermination.Voluntary }}</label>
                            </div>
                            <div class="flex-1 flex flex-col text-center">
                                <i class="text-3xl bi" :class="getDetailsTermination.SignedResignation==true ? 'bi-check-circle-fill text-green-600':'bi-x-circle-fill text-red-600'"></i>                            
                                <label class="text-xs mb-1 uppercase">Rehirable</label>
                            </div>
                            <div class="flex-1 flex flex-col text-center">
                                <i class="text-3xl bi" :class="getDetailsTermination.SignedResignation==true ? 'bi-check-circle-fill text-green-600':'bi-x-circle-fill text-red-600'"></i>                            
                                <label class="text-xs mb-1 uppercase">Signed Resignation</label>
                            </div>
                        </div>
                        <div class="flex space-x-3">
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.Reason + (getDetailsTermination.SubReason ? ' ('+getDetailsTermination.SubReason+')':'') }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Reason</label>
                            </div>
                            <div class="flex flex-col">
                                <div>{{ getDetailsTermination.LastDay }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Last Day Worked</label>
                            </div>
                            <div class="flex flex-col">
                                <div>{{ getDetailsTermination.TerminationDay }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Termination Date</label>
                            </div>
                        </div>
                        <div class="flex space-x-3">
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.Performance }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Last 3 months QA performance average</label>
                            </div>
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.Attendance }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Last 3 months attendance history</label>
                            </div>
                        </div>
                        <div class="flex" v-if="getDetailsTermination.Comment">
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.Comment }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Comment</label>
                            </div>
                        </div>
                        <div class="flex" v-if="getDetailsTermination.CommentHR">
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.CommentHR }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">Comment HR</label>
                            </div>
                        </div>
                    </div>
                    <div class="relative bg-slate-50 text-slate-700 text-left p-4 overflow-hidden">
                        <i class="absolute right-[5%] -top-7 bi bi-hospital text-9xl opacity-5"></i>
                        <div class="relative flex space-x-3 w-[80%]">
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.IMSS }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">IMSS Folio Number</label>
                            </div>
                            <div class="flex-1 flex flex-col">
                                <div>{{ getDetailsTermination.IMSSDate }}</div>
                                <label class="border-t-2 text-gray-400 text-xs mb-1 uppercase">IMSS Date</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="getDetailsTermination && getDetailsTermination.Status === 'cancelled'">
                <div class="flex flex-col bg-red-50 text-red-600 text-center p-6 rounded-lg">
                    <i class="bi bi-x text-5xl mb-3"></i>
                    Termination Cancelled
                </div>
            </div>
            <div v-else-if="getDetailsTermination && getDetailsTermination.Status === 'pending' && !hasEditorRoles">
                <div class="flex flex-col bg-amber-50 text-amber-600 text-center p-6 rounded-lg">
                    <i class="bi bi-clock-history text-5xl mb-3"></i>
                    Waiting Answer
                </div>
            </div>
            <div v-else-if="!getDetailsTermination || hasEditorRoles">
                <!-- Here is the general form and the form for HR -->
                <div class="border divide-y rounded" v-if="!getDetailsTermination || (humanResourcesStep && hasEditorRoles)">
                    <div class="flex-1 p-3" v-if="humanResourcesStep">
                        <label class="text-xs mb-0 uppercase">Category</label>
                        <select :disabled="disableReason || validInformation" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" 
                        v-model="information.category" required>
                            <option value="null" hidden>Select...</option>
                            <option v-for="event in getTerminationCategories" :value="event.label" :key="event.Id">{{event.label}}</option>
                        </select>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.information.category.$dirty" :valid="!$v.information.category.$invalid"></pill>
                        </div>
                    </div>
                    <div class="p-3">
                        <label class="text-xs mb-1 uppercase">Voluntary or Involuntary?</label>
                        <div class="radiogroup flex text-center">
                            <input type="radio" v-model="information.voluntary" name="voluntary" class="my-auto mr-1" value="Voluntary" id="rad-voluntary"
                            :disabled="validInformation"
                            required @change="bringReasons"><label class="flex-1" for="rad-voluntary">Voluntary</label>
                            <input type="radio" v-model="information.voluntary" name="voluntary" class="my-auto  mr-1" value="Involuntary" id="rad-involuntary"
                            :disabled="validInformation"
                            required @change="bringReasons"><label class="flex-1" for="rad-involuntary">Involuntary</label>
                        </div>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.information.voluntary.$dirty" :valid="!$v.information.voluntary.$invalid"></pill>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="flex-1 p-3 border-r">
                            <label class="text-xs mb-0 uppercase">Reason:</label>
                            <div class="flex justify-between" v-if="getDetailsTermination && getDetailsTermination.Reason">
                                {{ this.information.reason }}
                                <button @click="getDetailsTermination.Reason=null" class="bg-red-600 text-white text-sm rounded-full px-3"><i class="bi bi-arrow-left-right mr-2"></i>Change</button>
                            </div>
                            <select v-else :disabled="disableReason || validInformation" @change="setReasons($event)"  class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" required>
                                <!-- v-model="information.sub_reason" -->
                                <option value="null" hidden>{{this.optionText}}</option>
                                <optgroup v-for="(elements, key, index) in getReasons" :key="index" :label="key" >
                                    <option v-for="(event,index) in elements" :value="event[0]" :reason="key" :key="index">{{event[0]}}</option>
                                </optgroup>
                            </select>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.reason.$dirty" :valid="!$v.information.reason.$invalid"></pill>
                            </div>
                        </div>
                        <div class="px-10 py-3">
                            <label class="flex items-center text-xs mb-0 uppercase">
                                <input :disabled="validInformation" type="checkbox" class="focus:ring-blue-400 h-4 w-4 rounded mr-2" v-model="information.rehirable">
                                Rehirable
                            </label>
                            <label class="flex items-center text-xs mb-0 uppercase mt-3" v-if="humanResourcesStep">
                                <input :disabled="validInformation" type="checkbox" class="focus:ring-blue-400 h-4 w-4 rounded mr-2" v-model="information.resignation">
                                Signed Resignation
                            </label>
                        </div>
                    </div>

                    <div class="flex-1 p-3 border-r" v-if="information.resignation">
                        <label class="text-xs mb-0 uppercase">Upload the resignation file:</label>
                        <div class="flex-1 p-3">
                            <upload-files @changed="handleImages" :isMultiple='multiple_files' :fileTypes="file_types" :maxSize="max_file_size" :disabled="validInformation"/>
                        </div>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.information.file.$dirty" :valid="!$v.information.file.$invalid"></pill>
                        </div>
                    </div>

                    <div class="flex" v-if="!humanResourcesStep">
                        <div class="flex-1 flex flex-col p-3">
                            <label class="text-xs mb-0 uppercase">Last 3 months QA performance average:</label>
                            <select v-model="information.performance" :disabled="validInformation" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" required>
                                <option value="null" hidden>Select one...</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Good">Good</option>
                                <option value="Normal">Normal</option>
                                <option value="Poor">Poor</option>
                                <option value="Very Bad">Very Bad</option>
                            </select>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.performance.$dirty" :valid="!$v.information.performance.$invalid"></pill>
                            </div>
                        </div>
                        <div class="flex-1 flex flex-col p-3">
                            <label class="text-xs mb-0 uppercase">Last 3 months attendance history:</label>
                            <select v-model="information.attendance" :disabled="validInformation" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" required>
                                <option value="null" hidden>Select one...</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Good">Good</option>
                                <option value="Normal">Normal</option>
                                <option value="Poor">Poor</option>
                                <option value="Very Bad">Very Bad</option>
                            </select>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.attendance.$dirty" :valid="!$v.information.attendance.$invalid"></pill>
                            </div>
                        </div>
                    </div>
                    <div class="flex" v-else>
                        <div class="flex-1 flex flex-col p-3">
                            <label class="text-xs mb-0 uppercase">Last 3 months QA performance average:</label>
                            <div class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">
                                {{ information.performance }}
                            </div>
                        </div>
                        <div class="flex-1 flex flex-col p-3">
                            <label class="text-xs mb-0 uppercase">Last 3 months attendance history:</label>
                            <div class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">
                                {{ information.attendance }}
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="flex-1 flex flex-col p-3">
                            <label class="text-xs mb-0 uppercase">Last Day Worked:</label>
                            <date-picker id="datesId" class="!block !w-full" input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" v-model="information.attrition" 
                                :disabled-date="ableOnlyPastButToday"
                                :disabled="validInformation"
                                placeholder="Select a date" valueType="format" :editable="false" :custom-formatter="customFormatter">
                            </date-picker>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.attrition.$dirty" :valid="!$v.information.attrition.$invalid"></pill>
                            </div>
                        </div>
                        <div class="flex-1 flex flex-col p-3" v-if="getDetailsTermination && itemContains('HR')">
                            <label class="text-xs mb-0 uppercase">Termination Date:</label>
                            <date-picker id="terminationDate" class="!block !w-full" input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" v-model="information.termination_date" 
                                :disabled="validInformation"
                                placeholder="Select a date" valueType="format" :editable="false" :custom-formatter="customFormatter">
                            </date-picker>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.termination_date.$dirty" :valid="!$v.information.termination_date.$invalid"></pill>
                            </div>
                        </div>
                    </div>
                    <!-- this part is for HR form -->
                    <div class="p-3" v-if="getDetailsTermination && itemContains('HR')">
                        <label class="text-xs mb-0 uppercase">Comment:</label>
                        <p class="text-gray-600 border-b pb-3">{{ information.comment }}</p>
                        <label class="text-xs mb-0 uppercase">Comment:</label>
                        <textarea id="text-area-box" v-model="information.comment_hr" name="Comment"  :disabled="validInformation" class="w-full resize-none"
                        :class="{ 'border-red-500' : textIsInvalid, 'border-blue-500' : !textIsInvalid }" rows="3" maxlength="5000" placeholder="Write here..."></textarea>
                        <span class="text-gray-700 text-xs text-left">
                            <span class="text-red-700">*</span> Write at least 4 characters, max 5000.
                        </span>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.information.comment_hr.$dirty" :valid="!$v.information.comment_hr.$invalid"></pill>
                        </div>
                        <div class="text-center mt-3 space-x-3">
                            <button v-if="validInformation" @click="confirmRequestTermination(3,'pending')" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full">
                                <span class="uppercase text-xs">Confirm Request</span>
                            </button>
                            <button v-else @click="verifyDates" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full">
                                <span class="uppercase text-xs">Validate Request</span>
                            </button>
                            <button @click="confirmRequestTermination(2,'cancelled')" class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-full">
                                <i class="bi bi-x align-middle mr-2"></i><span class="uppercase text-xs">Cancel Request</span>
                            </button>
                        </div>
                    </div>
                    <!-- this part is for general form -->
                    <div class="p-3" v-else>
                        <label class="text-xs mb-0 uppercase">Comment:</label>
                        <textarea id="text-area-box" v-model="information.comment" name="Comment"  :disabled="validInformation" class="w-full resize-none"
                        :class="{ 'border-red-500' : textIsInvalid, 'border-blue-500' : !textIsInvalid }" rows="3" minlength="4" maxlength="5000" placeholder="Write here..."></textarea>
                        <span class="text-gray-700 text-xs text-left">
                            <span class="text-red-700">*</span> Write at least 4 characters, max 5000.
                        </span>
                        <div class="relative flex flex-row w-full">
                            <pill :dirty="$v.information.comment.$dirty" :valid="!$v.information.comment.$invalid"></pill>
                        </div>
                        <div class="text-center mt-3 p-2">
                            <button v-if="validInformation && isTrainingDismissal < 3" @click="setTrainingDismissal" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full">
                                <span class="uppercase text-xs">Request Training Termination</span>
                            </button>
                            <button v-else-if="validInformation" @click="storeRequestTermination" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full">
                                <span class="uppercase text-xs">Request Termination</span>
                            </button>
                            <button v-else @click="verifyDates" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full">
                                <span class="uppercase text-xs">Validate Request</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Here is the payroll form -->
                <div class="border divide-y rounded" v-else-if="payrollStep && hasEditorRoles">
                    <div class="p-3">
                        <div class="flex justify-content-center">
                            <div class="p-3">
                                <label class="text-xs mb-0 uppercase">Hire Date</label>
                                <span class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">{{ customFormatter(getDetailsTermination.HireDate) }}</span>
                            </div>
                            <div class="p-3">
                                <label class="text-xs mb-0 uppercase">Last Day Worked</label>
                                <span class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">{{ customFormatter(getDetailsTermination.LastDay) }}</span>
                            </div>
                            <div class="p-3">
                                <label class="text-xs mb-0 uppercase">Termination Date</label>
                                <span class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">{{ customFormatter(getDetailsTermination.TerminationDay) }}</span>
                            </div>
                            <div class="p-3">
                                <label class="text-xs mb-0 uppercase text-wrap">Res. letter</label>
                                <div v-if="getDetailsTermination.SignedResignation">
                                    <a :href="getLink(getDetailsTermination.File)" target="_blank" class="flex">
                                        <div class="bg-gray-600 rounded-full p-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf-fill text-white" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                                <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                                <span v-else type="text" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2">Not signed</span>
                            </div>
                        </div>
                        <div class="flex-1 p-3">
                            <label class="text-xs mb-0 uppercase">Category</label>
                            <select :disabled="disableReason || validInformation" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" 
                            v-model="information.category" required>
                                <option value="null" hidden>Select...</option>
                                <option v-for="event in getTerminationCategories" :value="event.label" :key="event.Id">{{event.label}}</option>
                            </select>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.category.$dirty" :valid="!$v.information.category.$invalid"></pill>
                            </div>
                        </div>
                        <div class="flex-1 p-3">
                            <label class="text-xs mb-0 uppercase">IMSS Folio Number</label>
                            <input type="text" class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" placeholder="..." v-model="information.imss">
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.imss.$dirty" :valid="!$v.information.imss.$invalid"></pill>
                            </div>
                        </div>
                        <div class="flex-1 p-3">
                            <label class="text-xs mb-0 uppercase">IMSS Date</label>
                            <date-picker id="datesId" class="!block !w-full" input-class="w-full border-b-2 px-3 block focus:border-gray-700 py-2" v-model="information.imss_date" 
                                :disabled-date="ableOnlyPastButToday"
                                placeholder="Select a date" valueType="format" :editable="false" :custom-formatter="customFormatter">
                            </date-picker>
                            <div class="relative flex flex-row w-full">
                                <pill :dirty="$v.information.imss_date.$dirty" :valid="!$v.information.imss_date.$invalid"></pill>
                            </div>
                        </div>
                        <div class="text-center mt-3 space-x-3">
                            <button @click="confirmRequestTermination(4,'completed')" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full">
                                <i class="bi bi-check2 align-middle mr-2"></i><span class="uppercase text-xs">Confirm Request</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="flex flex-col bg-amber-50 text-amber-600 text-center p-6 rounded-lg">
                        <i class="bi bi-clock-history text-5xl mb-3"></i>
                        Waiting Answer
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker                 from 'vue2-datepicker';
import moment                     from "moment";
//import Loading                    from "@/views/mockups/loading.vue"
import ProfileInformation         from '@/views/employees/profile/views/profile_information'
import UploadFiles  from '@/views/Utilities/upload_files/index'
import Pill from '@/views/mockups/ValidationPill';
import { required, requiredIf } from 'vuelidate/lib/validators';
import modal from '@/views/employees/terminations/modals/terminationComments';
import TerminationComments from '@/views/components/Incidences/Requests/components/terminationComments'
export default {
    components:{
        DatePicker,
        Pill,
        UploadFiles,
        //Loading,
        ProfileInformation,
        modal,
        TerminationComments
    },
    data() {
        return{
            multiple_files: false,
            file_types: ['application/pdf'],
            max_file_size: 5,
            validInformation: false,
            disableReason: true,
            sending: false,
            stepper: 0,
            optionText: "Select if Voluntary or Involuntary first",
            information: {
                id:                 0,
                employee:           null,
                voluntary:          null,
                reason:             null,
                sub_reason:         null,
                performance:        null,
                attendance:         null,
                attrition:          null,
                termination_date:   null,
                rehirable:          false,
                resignation:        false,
                category:           null,
                imss:               '',
                comment:            null,
                comment_hr:         null,
                imss_date:          '',
                step:               0,
                status:             null,
                file:               null
            },
            employee: this.$route.params.id,
            pending: false,
            loadData: false,
            windowCurrent: 'inventory',
            show_modal: false,
            modal_information: {
                text: 'Are you sure you want to proceed? This action cannot be undone.',
                icon: 'warning',
                employee: '',
                id: 0,
            },

            commentsKey: 0,
        }
    },
    validations () {
        return {
            information: {
                attrition: {
                    required
                },
                performance: {
                    required: requiredIf(function () {
                        return !this.humanResourcesStep && !this.payrollStep;
                    })
                },
                attendance: {
                    required: requiredIf(function () {
                        return !this.humanResourcesStep && !this.payrollStep;
                    })  
                },
                termination_date: {
                    required: requiredIf(function () {
                        return this.humanResourcesStep;
                    })
                },
                comment: {
                    required: requiredIf(function () {
                        return !this.humanResourcesStep && !this.payrollStep;
                    })
                },
                comment_hr: {
                    required: requiredIf(function () {
                        return this.humanResourcesStep;
                    })
                },
                voluntary: {
                    required
                },
                reason: {
                    required
                },
                imss: {
                    required: requiredIf(function () {
                        return this.payrollStep;
                    })
                },
                imss_date: {
                    required: requiredIf(function () {
                        return this.payrollStep;
                    })
                },                
                file: {
                    required: requiredIf(function () {
                        return this.information.resignation && this.humanResourcesStep;
                    })
                },              
                category: {
                    required: requiredIf(function () {
                        return this.humanResourcesStep || this.payrollStep;
                    })
                },              
            }
        }
    },
    computed: {
        ...mapGetters([
            'getReasons',
            'infoEmployee',
            'getInventoryEmployee',
            'getDetailsTermination',
            'getStepper',
            'getItemsEmployee',
            'getMsl',
            'getTerminationCategories',
            'getCommentsTermination',
        ]),
        textIsInvalid: function(){
            return this.information.comment === null || this.information.comment === '' || this.information.comment?.length < 4;
        },

        humanResourcesStep () {
            return this.getDetailsTermination && this.getDetailsTermination.order === 2;
        },

        payrollStep () {
            return this.getDetailsTermination && this.getDetailsTermination.order === 3;
        },

        hasEditorRoles () {
            return (this.getDetailsTermination && this.itemContains('HR')) || (this.getDetailsTermination && this.itemContains('Payroll'))
        },

        isTrainingDismissal () {
            const a = new Date(this.information?.attrition);
            const b = new Date(this.customFormatter(this.infoEmployee.HireDate));
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
            return Math.floor((utc1 - utc2) / _MS_PER_DAY);
        }
    },
    methods:{
        ...mapActions([
            'verifyTerminationDates',
            'fetchReasons',
            'sendNewTicket',
            'sendPredismissal',
            'loadMyProfileByEmpId',
            'fetchInventoryEmployee',
            'requestTermination',
            'updateTermination',
            'getTerminationRequest',
            'fetchTerminationsStepper',
            'sendTermination',
            'getItemsRequest',
            'getMSLRequestById',
            'fetchTerminationCategories',
            'addTerminationComment',
        ]),
        getLink (name) {
            if(name?.startsWith("http")) return name;
            return 'https://centris-documents.website-us-southeast-1.linodeobjects.com/linode_resignations/' + name;
        },
        customFormatter(date){
            return moment(date).format("YYYY-MM-DD");
        },

        setReasons(event){
            let trg = event.target.selectedOptions[0];
            this.information.reason = trg?.getAttribute("reason");
            this.information.sub_reason = trg.value;
        },
        
        verifyDates(){
            this.$v.information.$touch();
            if(this.$v.information.$invalid) return;
            this.sending = true;
            this.information.employee = this.employee;

            this.verifyTerminationDates(this.information).then(()=>{
                this.validInformation = true;
                this.sending = false;
            }).catch((error)=>{
                let message =  error.response.data.message ?? 'Something went wrong';
                let title = error.response.data.title ?? 'Something went wrong';
                let index_end = message.indexOf('(SQL:');
                let sub_stringed = index_end >= 0 ? message.substring(0, index_end) : message;
                this.$fire({
                    type: 'warning',
                    title: `${title}`,
                    text:`${sub_stringed}`,
                    showConfirmButton: true,                
                });
                this.validInformation = false;
                this.sending = false;
            })
        },

        ableOnlyPastButToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },

        bringReasons (){
            this.optionText = "Loading reasons...";
            this.disableReason = true;
            this.information.reason = null;
            this.information.sub_reason = null;
            let type = this.information.voluntary;
            this.fetchReasons(type).then(()=>{
                this.optionText = "Select an Option";
                this.disableReason = false;
            })
        },

        storeRequestTermination() {
            this.$v.information.$touch();
            if(this.$v.information.$invalid) return;
            let objData = {
                EmpID: this.employee,
                Voluntary: this.information.voluntary,
                Reason: this.information.reason,
                SubReason: this.information.sub_reason,
                Rehirable: this.information.rehirable ?? false,
                Performance: this.information.performance,
                Attendance: this.information.attendance,
                LastDay: this.information.attrition,
                TerminationDate: this.information.termination_date,
                Comment: this.information.comment
            }
            this.requestTermination(objData).then(() => {
                let dismissingData = { 
                    newStatus: "Predismissal",
                    comment: this.information.comment,
                    action: 'Pre Dismissing',
                    reason: 'Pre Dismissal',
                    recID: this.employee
                }
                this.sendPredismissal(dismissingData).then(() => {
                    this.getTerminationRequest(this.employee);
                })
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err.message
                })
            })
        },

        confirmRequestTermination(step,status) {
            if(status !== 'cancelled') {
                this.$v.information.$touch();
                if(this.$v.information.$invalid) return;
            }
            this.$confirm("This action is irreversible", "Do you want to confirm?", "warning").then((result) => {
                if (result) {
                    let objData = new FormData();
                    objData.append('ID', this.information.id);
                    objData.append('Voluntary', this.information.voluntary);
                    objData.append('Reason', this.information.reason);
                    objData.append('SubReason', this.information.sub_reason);
                    objData.append('Rehirable', this.information.rehirable);
                    objData.append('SignedResignation', this.information.resignation);
                    objData.append('LastDay', this.information.attrition);
                    objData.append('TerminationDate', this.information.termination_date);
                    objData.append('CommentHR', this.information.comment_hr);
                    objData.append('IMSS', this.information.imss);
                    objData.append('IMSSDate', this.information.imss_date);
                    objData.append('Category', this.information.category);
                    objData.append('file', this.information.file);
                    objData.append('StepID', step);
                    objData.append('Status', status);
                    this.updateTermination(objData).then(() => {
                        if(step === 4) {
                            this.terminateEmployee();
                        } else {
                            this.$router.push({name: 'terminations.index'});  
                        }
                    }).catch((err) => {
                        this.$fire({
                            type: 'error',
                            title: err.message
                        })
                    })
                }
            })
        },

        terminateEmployee: async function () {
            let objData = {
                employee_id:        this.employee,
                type:               'Termination',
                reason:             this.information.reason,
                sub_reason:         this.information.sub_reason,
                voluntary:          this.information.voluntary === 'Voluntary' ? 'Yes' : 'No',
                rehirable:          this.information.rehirable ? 'Yes' : 'No',
                attrition_date:     this.information.attrition,
                termination_date:   this.information.termination_date,
                sign_letter:        this.getDetailsTermination.SignedResignation ? 'Yes' : 'No',
                comment:            this.information.comment,
                ticked_id:          null,
                request_id:         this.information.id
            };
            
            this.sendTermination(objData).then(() =>{
                this.$fire({
                    type: 'success',
                    title: 'The employee has been terminated successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    // this.$router.push({name: 'terminations.index'});
                    this.$router.go(-1);              
                });
            }).catch(()=>{
                this.$fire({
                    type: 'error',
                    title: 'Oops, something went wrong!',
                    text: 'Please report to the system administrator',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
        },

        itemContains(n) {
            return this.hasPermission(n) || this.hasPermission('SystemAdministrator');
        },

        changeWindow: function(id){
            this.windowCurrent = id;
        },

        handleImages(files){
            this.information.file = files[0];
        },

        showModal (item) {
            this.modal_information.id = item.ID;
            this.modal_information.employee = item.EmpID;
            this.show_modal = true;
        },

        async confirmation (data) {
            if (data.status) {
                let obj = new FormData();
                obj.append('id', this.information.id);
                obj.append('comment', data.textbox);
                obj.append('file', data.file);
                await this.addTerminationComment(obj);
            }
            this.commentsKey++;
            this.show_modal = false;
        },

        setTrainingDismissal () {
            this.$v.information.$touch();
            if(this.$v.information.$invalid) return;
            document.getElementById("pro-loading").style.display = "flex";  
            let objData = {
                EmpID: this.employee,
                Voluntary: this.information.voluntary,
                Reason: this.information.reason,
                SubReason: this.information.sub_reason,
                Rehirable: this.information.rehirable ?? false,
                Performance: this.information.performance,
                Attendance: this.information.attendance,
                LastDay: this.information.attrition,
                TerminationDate: this.information.termination_date,
                Comment: this.information.comment
            }
            this.requestTermination(objData).then(() => {
                this.getTerminationRequest(this.employee).then(() => {
                    this.information.id = this.getDetailsTermination?.ID;
                    this.information.category = this.getDetailsTermination?.Category;
                    this.information.voluntary = this.getDetailsTermination?.Voluntary;
                    this.information.reason = this.getDetailsTermination?.Reason;
                    this.information.sub_reason = this.getDetailsTermination?.SubReason;
                    this.information.rehirable = this.getDetailsTermination?.Rehirable;
                    this.information.performance = this.getDetailsTermination?.Performance;
                    this.information.attendance = this.getDetailsTermination?.Attendance;
                    this.information.termination_date = this.getDetailsTermination?.TerminationDay;
                    this.information.attrition = this.getDetailsTermination?.LastDay;
                    this.information.comment = this.getDetailsTermination?.Comment;
                    this.information.comment_hr = this.getDetailsTermination?.CommentHR;
                    this.information.resignation = this.getDetailsTermination?.SignedResignation;
                    
                    let objData = new FormData();
                    objData.append('ID', this.information.id);
                    objData.append('Voluntary', this.information.voluntary);
                    objData.append('Reason', this.information.reason);
                    objData.append('SubReason', this.information.sub_reason);
                    objData.append('Rehirable', this.information.rehirable);
                    objData.append('SignedResignation', this.information.resignation);
                    objData.append('LastDay', this.information.attrition);
                    objData.append('TerminationDate', this.customFormatter(new Date()));
                    objData.append('CommentHR', 'This is a training dismissal generated by EMS');
                    objData.append('IMSS', this.information.imss);
                    objData.append('IMSSDate', this.information.imss_date);
                    objData.append('Category', 'Recruiting');
                    objData.append('file', this.information.file);
                    objData.append('StepID', '4');
                    objData.append('Status', 'completed');
                    this.updateTermination(objData).then(() => {
                        this.terminateEmployee().then(() => {
                            document.getElementById("pro-loading").style.display = "none";  
                        })
                    }).catch((err) => {
                        this.$fire({
                            type: 'error',
                            title: err.message
                        })
                    })
                });
            }).catch((err) => {
                this.$fire({
                    type: 'error',
                    title: err.message
                })
            })
        }
    },
    created() {
        this.modal_information.employee = this.getDetailsTermination?.ID;
        this.loadMyProfileByEmpId(this.employee).then(() => {
            this.loadData = true;
        });
        
        this.fetchInventoryEmployee(this.employee);
        this.getMSLRequestById(this.employee);
        this.getItemsRequest(this.employee);
        this.fetchTerminationCategories();
        
        this.fetchTerminationsStepper();
        this.getTerminationRequest(this.employee).then(() => {
            this.information.id = this.getDetailsTermination?.ID;
            this.information.category = this.getDetailsTermination?.Category;
            this.information.voluntary = this.getDetailsTermination?.Voluntary;
            this.bringReasons();
            this.information.reason = this.getDetailsTermination?.Reason;
            this.information.sub_reason = this.getDetailsTermination?.SubReason;
            this.information.rehirable = this.getDetailsTermination?.Rehirable;
            this.information.performance = this.getDetailsTermination?.Performance;
            this.information.attendance = this.getDetailsTermination?.Attendance;
            this.information.termination_date = this.getDetailsTermination?.TerminationDay;
            this.information.attrition = this.getDetailsTermination?.LastDay;
            this.information.comment = this.getDetailsTermination?.Comment;
            this.information.comment_hr = this.getDetailsTermination?.CommentHR;
            this.information.resignation = this.getDetailsTermination?.SignedResignation;
            document.getElementById("pro-loading").style.display = "none";        
        });

        this.$emit('set-title', 'Employee Change');
    },
    mounted() {
        document.getElementById("pro-loading").style.display = "flex";
    }
 }
</script>
<style scoped>
    .radiogroup {
        font-size: 90%;
        border-radius: 30px;
        border: 2px solid #2563eb;
    }
    .radiogroup input[type="radio"] {
        display: none;
    }
    .radiogroup input[type="radio"] + label {
        display: inline-block;
        color: #2563eb;
        line-height: 2;
        padding: 0 15px;
        border-radius: 20px;
        margin: 0;
        cursor: pointer;
    }
    .radiogroup input[type="radio"]:checked + label {
        color: #fff;
        background-color: #2563eb;
    }
    .loader div {
      animation-duration: 1.5s;
    }
    
    .loader div:first-child {
      animation-delay: 0.1s;
    }
    
    .loader div:nth-child(2) {
      animation-delay: 0.3s;
    }
    
    .loader div:nth-child(3) {
      animation-delay: 0.6s;
    }
    </style>