<template>
    <modal-base @close="close" :isVisible="is_visible" >
        <template>
            <form @submit.prevent="save" id="fieldForm" class="flex flex-col" :inert="isLoading">
                <div class="text-left text-2xl font-bold">
                    <span>Create</span> <span class="capitalize text-blue-500">{{
                        childType
                    }}</span>
                    <hr class="my-3">
                </div>
                <div class="flex flex-col space-y-4">
                    <div>
                        <div class="text-left">
                            <span class="text-lg font-semibold">General Information</span>
                        </div>
                        <div v-if="shouldBePresent('name')" class="flex flex-col items-start">
                            <span class="py-0.5">Name</span>
                            <input required type="text" v-model="params.label"
                                   class="w-full rounded-lg bg-gray-50 px-1 peer border-[1px] focus:ring"
                                   pattern="[A-Z_0-9]*" @input="upperCaseInput">
                            <span class="text-xs">The name can only include: capital letters, underscores, and numbers.</span>
                        </div>
                        <div v-if="shouldBePresent('description')"
                             class="flex flex-col items-start">
                            <span>Description</span>
                            <textarea required rows="3" maxlength="255" v-model="params.description"
                                      class="w-full rounded-lg bg-gray-50 px-1 border-[1px] focus:ring"></textarea>
                        </div>
                    </div>
                    <div v-if="shouldBePresent('address')" class="flex flex-col">
                        <div class="text-left">
                            <span class="text-lg font-semibold">Location</span>
                        </div>
                        <div class="flex flex-col text-left">
                            <span>Street</span>
                            <input v-model="params.address" type="text"
                                   class="w-full rounded-lg bg-gray-50 px-1 border-[1px]">
                        </div>
                        <div class="flex w-full flex-row justify-stretch space-x-2">
                            <div class="flex-1 flex-col text-left">
                                <span>Country</span>
                                <select v-model="params.country" required
                                        class="box-content h-6 w-full rounded-lg bg-gray-50 border-[1px]">
                                    <option value="USA">USA</option>
                                </select>
                            </div>
                            <div class="flex-1 flex-col text-left">
                                <span>State</span>
                                <us-states-dropdown v-model="params.state"
                                                    required></us-states-dropdown>
                            </div>
                            <div class="flex-1 flex-col text-left">
                                <span>Zip</span>
                                <input v-model="params.zip" required type="text" inputmode="numeric"
                                       pattern="[0-9]*" maxlength="5"
                                       class="w-full rounded-lg bg-gray-50 px-1 border-[1px]">
                            </div>
                        </div>
                    </div>
                    <div v-if="isAssignable">
                        <div class="text-left">
                            <span class="text-lg font-semibold">Attach To</span>
                        </div>
                        <multiple-dropdown v-model="selectedParents"
                                           :options="getFilteredDefinitionNames"/>
                    </div>
                    <div class="flex justify-end space-x-1">
                        <button class="h-8 w-20 rounded-lg border-2 bg-gray-100 hover:bg-gray-200"
                                @click.prevent="close">
                            Cancel
                        </button>
                        <button type="submit" form="fieldForm"
                                class="h-8 w-20 rounded-lg border-2 border-blue-600 bg-blue-500 text-white hover:bg-blue-600">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </template>
    </modal-base>
</template>
<script>

import ModalBase from "@/views/components/Modal/modalBase.vue";
import UsStatesDropdown from "@/views/components/Organization/UsStatesDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import MultipleDropdown from "@/views/components/Organization/MultipleDropdown.vue";

export default {
    components: { MultipleDropdown, UsStatesDropdown, ModalBase },
    props: {
        node: {
            type: Object,
            required: true,
        },

        is_visible: {
            default: true,
        }
    },
    data () {
        return {
            selectedParents: { added: [], removed: [] },
            isLoading: false,
            params: {
                label: '',
                description: '',
                address: '',
                country: '',
                state: '',
                zip: '',
            },
        }
    },
    created () {
        if ( this.isAssignable === true ) {
            this.isLoading = true;

            Promise.all([
                this.fetchDefinitionNamesByType({ params: { type: this.parentType } }),
            ]).then(
                () => {
                    this.isLoading = false;
                }
            );
        }
    },
    computed: {
        ...mapGetters([
            'getFilteredDefinitionNames',
        ]),
        parentType () {
            const parentTypes = {
                'campaign': 'department',
                'title': 'department',
                'lob': 'site',
                'site': 'lob'
            };

            return parentTypes[this.childType];
        },
        isAssignable () {
            return ['campaigns', 'titles', 'lob', 'site', 'lobs', 'sites'].includes(this.identifier);
        },
        identifier () {
            return (this.node.type === 'category') ? this.node.id.split('-').reverse()[0] : this.node.type;
        },
        childType () {
            const childrenTypes = {
                departments: 'department',
                department: 'campaign',
                campaigns: 'campaign',
                titles: 'title',
                lobs: 'lob',
                projects: 'project',
                sites: 'site',
                site: 'lob'
            };

            return childrenTypes[this.identifier];
        },
        intendedElements () {
            const values = {
                department: ['name',],
                campaign: ['name', 'description', 'address', 'timeZone'],
                title: ['name',],
                lob: ['name', 'description'],
                project: ['name',],
                site: ['name',],
            }

            return values[this.childType];
        },
    },
    methods: {
        ...mapActions([
            'saveDefinitions',
            'fetchDefinitionNamesByType',
            'saveDepartmentRelationships'
        ]),
        upperCaseInput () {
            this.params.label = this.params.label.toUpperCase().replace(' ', '_');
        },
        close () {
            if ( this.isLoading ) {
                return;
            }
            this.$emit('close');
        },
        shouldBePresent (fieldName) {
            let appropriateFields = this.intendedElements;
            return appropriateFields.includes(fieldName);
        },
        save () {
            this.isLoading = true;
            let parentId = this.node.id;

            if ( typeof parentId === "string" ) {
                parentId = parseInt(parentId.split('-')[0])
            }

            const payload = {
                id: 'newElement',
                parentId: parentId,
                parent_type: this.node.type,
                type: this.childType,
                isActive: this.node.isActive,
                params: this.params,
            }

            this.saveDefinitions(
                payload
            ).then(
                (response) => {
                    this.$emit('update', true);

                    this.$fire({
                        type: 'success',
                        titleText: `Successfully created ${this.params.label}`,
                        showConfirmButton: true,
                    });

                    const payloadTwo = {
                        relationship: this.childType,
                        anchorType: this.childType,
                        anchorId: response.data.element.id,
                        attachedType: this.parentType,
                        toAdd: this.selectedParents.added.map(x => x.id),
                        toRemove: this.selectedParents.removed.map(x => x.id),
                    };

                    if ( this.isAssignable ) {
                        this.saveDepartmentRelationships(
                            payloadTwo
                        ).catch(
                            () => {
                                this.$fire({
                                    type: 'error',
                                    titleText: `Could not attach ${this.params.label} to the desired departments`,
                                    showConfirmButton: true,
                                });
                            }
                        );
                    }
                }
            ).catch(
                () => {
                    this.$fire({
                        type: 'error',
                        titleText: `Could not create ${this.params.label}`,
                        text: 'Please confirm the name is unique.',
                        showConfirmButton: true,
                    });
                }
            ).finally(
                () => {
                    this.isLoading = false;
                    this.close();
                }
            );
        },
    },
}
</script>
