import axios from '@/config/axios'
import { API_URL } from '../actions/config'

const state = {
    msl: [],
    status: false,
    loading: false,
    details: [],
    list: [],
    pagination: [],
    availableYears: []
};
const getters = {
    getMsl: (state) => state.msl,
    getMSLDetails: (state) => state.details,
    getLoadingMSL: (state) => state.loading,
    getMSLRequestStatus: (state) => state.status,
    getMSLList: (state) => state.list,
    getMslPagination: (state) => state.pagination,
    getAvailableYears: (state) => state.availableYears,
};
const actions = {

    async fetchAllMsl ({commit}, data){   
        const endpoint = API_URL + `api/v4/msl/filtered?page=${data.page}`;
        const response = await axios.post(endpoint, data);
        commit('setMsl',response.data.history);
        commit( 'setPagination', {meta: response.data.meta, links: response.data.links});

    },

    async fetchMslList ({commit}, data){   
        const endpoint = API_URL + 'api/v4/msl/list';
        const response = await axios.post(endpoint, data);
        commit('setMslList',response.data);
    },
    
    async storeMedicalLeave({commit}, data){
        const endpoint = API_URL + 'api/v4/msl/store-medical-leave';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setMSLRequestStatus', true); 
            return res.data;
        });
        return response;
    },

    async editMedicalLeave({commit}, data){
        const endpoint = API_URL + 'api/v4/msl/edit-medical-leave';
        const response = await axios.post( endpoint, data).then((res) =>{
            commit('setMSLRequestStatus', true); 
            return res.data;
        });
        return response;
    },

    async getMSLRequestById({commit}, employee){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/msl/get-msl-requests/' + employee;
        const response = await axios.get(endpoint).then((res) => {
            commit('setMsl', res.data.list);
            commit('setLoading', false);
            return res.data;
        })
        return response;
    },

    async fetchMedicalRequestDetails({commit}, id){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/msl/get-medical-request-details/' + id;
        const response = await axios.get(endpoint).then((res) => {
            commit('setDetails', res.data.details);
            commit('setLoading', false);
            return res.data;
        })
        return response;
    },

    async approveMedicalRequest({commit}, data){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/msl/approve-medical-request/'+data.id+'/'+data.fd;
        const response = await axios.get(endpoint).then((res) => {
            commit('setLoading', false);
            return res.data;
        })
        return response;
    },

    async declineMedialRequest({commit}, id){
        commit('setLoading', true);
        const endpoint = API_URL + 'api/v4/msl/decline-medical-request/' + id;
        const response = await axios.get(endpoint).then((res) => {
            commit('setLoading', false);
            return res.data;
        })
        return response;
    },

    async fetchAvailableYears({commit}){
        const endpoint = API_URL + 'api/v4/msl/available-years';
        const response = await axios.get(endpoint).then((res) => {
            commit('setYears', res.data.years);
            console.log(res);
            return res.data;
        })
        return response;
    },
};
const mutations = {
    setYears: (sate, availableYears) => (state.availableYears = availableYears),
    setMsl: (state, msl) => (state.msl = msl),
    setLoading: (state, loading) => (state.loading = loading),
    setDetails: (state, details) => (state.details = details),
    setMSLRequestStatus: (state, status) => (state.status = status),
    setMslList: (state, list) => (state.list = list),
    setPagination: (state, pagination) => (state.pagination = pagination),
};
export default {
    state,
    getters,
    actions,
    mutations
};