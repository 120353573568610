<template>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="absolute inset-0 bg-black opacity-50" @click="closeOnOutsideClick"></div>
        <div class="relative z-60 bg-white p-8 rounded-xl shadow-lg shrink-0" :class="width">
            <button class="absolute top-3 right-3 text-gray-500 hover:text-gray-700" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>

            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false,
            required: false
        },
        width: {
            type: String,
            default: 'w-1/2'
        },
        outsideClick: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        closeOnOutsideClick() {
            if (this.outsideClick) this.close();
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>