<template>
  <modal-base @close="close" :isVisible="is_visible">
    <template>
      <form @submit.prevent="save" id="fieldForm" class="flex flex-col" :inert="isLoading">
        <div class="text-left text-2xl font-bold">
          <span>Edit</span> <span class="capitalize text-blue-500">{{ childType }}s</span>
          <hr class="my-3">
        </div>
        <div class="text-left">
          <span class="text-lg font-semibold">Attached</span>
        </div>
        <div class="flex flex-col space-y-4">
          <multiple-dropdown :options="getFilteredDefinitionNames" :starting="startingParents"
                             v-model="parentChanges"/>
          <div v-if="childType !== 'lob'" class="flex flex-col items-start justify-start">
            <span class="text-xs">The {{ childType }} you want to add, doesn't exist yet?</span>
            <button
                class="flex h-7 w-40 items-center justify-center rounded-xl border-2 border-blue-600 bg-blue-500 text-white space-x-1 hover:bg-blue-600"
                @click.prevent="openCreationModal">
              <i class="bi bi-plus"/>
              <span class="capitalize">Create {{ childType }}</span>
            </button>
          </div>
          <div class="flex justify-end space-x-1">
            <button class="h-8 w-20 rounded-lg border-2 bg-gray-100 hover:bg-gray-200"
                    @click.prevent="close">
              Cancel
            </button>
            <button type="submit" form="fieldForm" :disabled="disableSaveButton"
                    class="h-8 w-20 rounded-lg border-2 border-blue-600 bg-blue-500 text-white enabled:hover:bg-blue-600 disabled:opacity-50">
              Save
            </button>
          </div>
        </div>
      </form>
    </template>
  </modal-base>
</template>
<script>

import ModalBase from "@/views/components/Modal/modalBase.vue";
import { mapActions, mapGetters } from "vuex";
import MultipleDropdown from "@/views/components/Organization/MultipleDropdown.vue";

export default {
    components: { MultipleDropdown, ModalBase },
    props: {
        node: {
            type: Object,
            required: true,
        },

        is_visible: {
            default: true,
        }
    },
    data () {
        return {
            startingParents: [],
            parentChanges: {added: [], removed: []},
            isLoading: false,
            params: {
                label: '',
                description: '',
                street: '',
                country: '',
                state: '',
                zip: '',
            },
        }
    },
    beforeMount () {
        this.isLoading = true;

        const namesPayload = { type: this.childType, id: this.parentId };
        const relationsPayload = {
            type: this.childType,
            id: this.parentId,
            reverse: false,
        }
        Promise.all([
            this.fetchDefinitionNamesByType({ params: namesPayload }),
            this.fetchDepartmentChildren({ params: relationsPayload }),
        ]).then(
            () => {
                this.startingParents = this.getDepartmentChildren;
                this.isLoading = false;
            }
        );
    },
    computed: {
        ...mapGetters([
            'getFilteredDefinitionNames',
            'getDepartmentChildren',
        ]),
        parentId () {
            return (Number.isInteger(this.node.id)) ? this.node.id : this.node.id.split('-')[0];
        },
        disableSaveButton () {
            return this.parentChanges['added']?.length === 0 && this.parentChanges['removed']?.length === 0;
        },
        childType () {
            const childrenTypes = {
                departments: 'department',
                department: 'campaign',
                campaigns: 'campaign',
                titles: 'title',
                lobs: 'lob',
                projects: 'project',
                sites: 'site',
                site: 'lob'
            };

            let identifier = (
                (this.node.type === 'category') ? this.node.id.split('-').reverse()[0] : this.node.type
            );

            return childrenTypes[identifier];
        },
        parentType () {
            const parentTypes = {
                'campaign': 'department',
                'title': 'department',
                'lob': 'site',
                'site': 'lob'
            };

            return parentTypes[this.childType];
        },
    },
    methods: {
        ...mapActions([
            'saveDefinitions',
            'fetchDefinitionNamesByType',
            'fetchDepartmentChildren',
            'saveDepartmentRelationships',
        ]),
        openCreationModal() {
            this.$emit('force');
        },
        close () {
            if ( this.isLoading ) {
                return;
            }
            this.$emit('close');
        },
        save () {
            this.isLoading = true;

            const payload = {
                relationship: this.childType,
                anchorType: this.parentType,
                anchorId: this.parentId,
                attachedType: this.childType,
                toAdd: this.parentChanges.added.map(x => x.id),
                toRemove: this.parentChanges.removed.map(x => x.id),
            };

            this.saveDepartmentRelationships(payload).then(
                () => {
                    this.$emit('update', true);
                    this.$fire({
                        title: `Successfully edited the attached ${this.childType}s`,
                        showConfirmButton: true,
                        type: 'success'
                    });
                }
            ).catch(
                () =>{
                    this.$fire({
                        showConfirmButton: true,
                        type: 'error',
                        title: 'Something went wrong.'
                    })
                }
            ).finally(
                () => {
                    this.isLoading = false;
                    this.close();
                }
            );
        },
    }
}
</script>
