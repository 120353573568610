<template>
  <modal-base @close="close" :isVisible="is_visible">
    <template>
      <form @submit.prevent="save" id="fieldForm" class="flex flex-col" :inert="isLoading">
        <div class="text-left text-2xl font-bold">
          <span>Edit</span> <span class="capitalize text-blue-500">{{ type }}</span>
          <hr class="my-3">
        </div>
        <div class="flex flex-col space-y-4">
          <div>
            <div class="text-left">
              <span class="text-lg font-semibold">General Information</span>
            </div>
            <div class="flex flex-col items-start">
              <span class="py-0.5">Name</span>
              <input required type="text" v-model="params.label" disabled
                     class="w-full rounded-lg bg-gray-50 px-1 peer border-[1px] focus:ring disabled:opacity-50"
                     pattern="[A-Z_0-9]*" @input="upperCaseInput">
              <span class="text-xs">Names cannot be edited.</span>
            </div>
            <div v-if="shouldBePresent('description')" class="flex flex-col items-start">
              <span>Description</span>
              <textarea required rows="3" maxlength="255" v-model="params.description"
                        class="w-full rounded-lg bg-gray-50 px-1 border-[1px] focus:ring"></textarea>
            </div>
          </div>
          <div v-if="shouldBePresent('address')" class="flex flex-col">
            <div class="text-left">
              <span class="text-lg font-semibold">Location</span>
            </div>
            <div class="flex flex-col text-left">
              <span>Street</span>
              <input v-model="params.address" type="text"
                     class="w-full rounded-lg bg-gray-50 px-1 border-[1px]">
            </div>
            <div class="flex w-full flex-row justify-stretch space-x-2">
              <div class="flex-1 flex-col text-left">
                <span>Country</span>
                <select v-model="params.country" required
                        class="box-content h-6 w-full rounded-lg bg-gray-50 border-[1px]">
                  <option value="USA">USA</option>
                </select>
              </div>
              <div class="flex-1 flex-col text-left">
                <span>State</span>
                <us-states-dropdown v-model="params.state" required></us-states-dropdown>
              </div>
              <div class="flex-1 flex-col text-left">
                <span>Zip</span>
                <input v-model="params.zip" required type="text" inputmode="numeric"
                       pattern="[0-9]*" maxlength="5"
                       class="w-full rounded-lg bg-gray-50 px-1 border-[1px]">
              </div>
            </div>
          </div>
          <div v-if="isAssignable">
            <div class="text-left">
              <span class="text-lg font-semibold">Attach To</span>
            </div>
            <multiple-dropdown v-model="selectedParents" :options="getFilteredDefinitionNames"
                               :starting="startingParents"/>
          </div>
          <div class="flex justify-end space-x-1">
            <button class="h-8 w-20 rounded-lg border-2 bg-gray-100 hover:bg-gray-200"
                    @click.prevent="close">
              Cancel
            </button>
            <button type="submit" form="fieldForm"
                    :disabled="intendedElements.length === 0 && !isAssignable"
                    class="h-8 w-20 rounded-lg border-2 border-blue-600 bg-blue-500 text-white enabled:hover:bg-blue-600 disabled:opacity-50">
              Save
            </button>
          </div>
        </div>
      </form>
    </template>
  </modal-base>
</template>
<script>

import ModalBase from "@/views/components/Modal/modalBase.vue";
import UsStatesDropdown from "@/views/components/Organization/UsStatesDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import MultipleDropdown from "@/views/components/Organization/MultipleDropdown.vue";

export default {
    components: { MultipleDropdown, UsStatesDropdown, ModalBase },
    props: {
        node: {
            type: Object,
            required: true,
        },

        is_visible: {
            default: true,
        }
    },
    data () {
        return {
            selectedParents: { added: [], removed: [] },
            isLoading: false,
            params: {
                label: this.node?.label,
                description: this.node?.description,
                address: this.node?.address,
                country: this.node?.country,
                state: this.node?.state,
                zip: this.node?.zip,
            },
            startingParents: []
        }
    },
    beforeMount () {
        if ( this.isAssignable === true ) {
            this.isLoading = true;

            Promise.all([
                this.fetchDefinitionNamesByType({ params: { type: this.parentType } }),
                this.fetchDepartmentChildren({
                    params: {
                        type: this.type,
                        id: this.node.id,
                        reverse: true,
                    }
                }),
            ]).catch(
                () => {
                    this.isLoading = false;
                    this.close();

                    this.$fire({
                        showConfirmButton: true,
                        title: 'Something went wrong.',
                        type: 'error',
                    });
                }
            ).then(
                () => {
                    this.startingParents = this.getDepartmentChildren;
                    this.isLoading = false;
                }
            );
        }
    },
    computed: {
        ...mapGetters([
            'getFilteredDefinitionNames',
            'getDepartmentChildren',
        ]),
        parentType () {
            const parentTypes = {
                'campaign': 'department',
                'title': 'department',
                'lob': 'site',
            };

            return parentTypes[this.type];
        },
        isAssignable () {
            if ( typeof this.node.id === 'string' ) {
                return false;
            } else {
                return ['title', 'campaign', 'lob'].includes(this.type);
            }
        },
        type () {
            return this.node.type
        },
        intendedElements () {
            const values = {
                department: [],
                campaign: ['description', 'address'],
                title: [],
                lob: ['description'],
                project: [],
                site: [],
            }

            return values[this.type];
        },
    },
    methods: {
        ...mapActions([
            'editDefinition',
            'fetchDefinitionNamesByType',
            'saveDepartmentRelationships',
            'fetchDepartmentChildren'
        ]),
        upperCaseInput () {
            this.params.label = this.params.label.toUpperCase();
        },
        close () {
            if ( this.isLoading ) {
                return;
            }
            this.$emit('close');
        },
        shouldBePresent (fieldName) {
            let appropriateFields = this.intendedElements;
            return appropriateFields.includes(fieldName);
        },
        saveIfNeeded () {
            return new Promise((resolve, reject) => {
                if ( this.intendedElements.length > 0 ) {
                    let parentId = this.node.id;

                    if ( typeof parentId === "string" ) {
                        parentId = parentId.split('-')[0];
                    }

                    const payload = {
                        id: this.node.id,
                        parent_id: parentId,
                        parent_type: this.node.type,
                        type: this.type,
                        isActive: this.node.isActive,
                        params: this.params,
                    }

                    this.editDefinition(
                        payload,
                    ).then(
                        (response) => {
                            resolve(response);
                        }
                    ).catch(
                        (error) => {
                            this.$fire({
                                type: 'error',
                                titleText: `Could not edit ${this.params.label}`,
                                showConfirmButton: true,
                            });
                            reject(error);
                        }
                    )
                } else {
                    resolve();
                }
            })
        },
        save () {
            this.isLoading = true;


            this.saveIfNeeded().then(
                () => {
                    if ( !this.isAssignable || this.selectedParents.added.length === 0 && this.selectedParents.removed.length === 0 ) {
                        return new Promise((resolve) => {
                            resolve();
                        });
                    }

                    const payloadTwo = {
                        relationship: this.type,
                        anchorType: this.type,
                        anchorId: this.node.id,
                        attachedType: this.parentType,
                        toAdd: this.selectedParents.added.map(x => x.id),
                        toRemove: this.selectedParents.removed.map(x => x.id),
                    };

                    return this.saveDepartmentRelationships(payloadTwo);
                }
            ).then(
                () => {
                    this.$fire({
                        type: 'success',
                        titleText: `Successfully edited ${this.params.label}`,
                        showConfirmButton: true,
                    });
                }
            ).catch(
                () => {
                    this.$fire({
                        showConfirmButton: true,
                        title: `Could not attach ${this.type} to ${this.parentType}.`,
                        type: 'error',
                    });
                }
            ).finally(
                () => {
                    this.isLoading = false;
                    this.close();
                    this.$emit('update', true);
                }
            );
        },
    },
}
</script>
