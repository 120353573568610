<template>
    <div class="root-container flex flex-col">
        <TerminationsSearchBar :key="reset_key" @toggle="changeFilters" class="mb-2.5"/>
        <div class="main overflow-auto h-fit w-full shadow-md rounded-t-[0.5rem]">
            <table class="light-table rounded-t-[0.5rem]">
                <thead class="sticky top-0 rounded-lg overflow-clip">
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Employee</th>
                        <th>Requested By</th>
                        <th>Site</th>
                        <th>Hire Date</th>
                        <th>Last Day Worked</th>
                        <th>Termination date</th>
                        <th>Resignation</th>
                        <th>Step</th>
                        <th>Status</th>
                        <th>Last Update</th>
                    </tr>
                </thead>
                <tbody class="animate-pulse divide-y" v-if="this.loading">
                    <tr v-for="i in 3" :key="i" class="hover:bg-slate-100">
                        <td v-for="i in 12" :key="i"><div class="h-2 bg-slate-200 rounded mx-2 my-3"></div></td>
                    </tr>
                </tbody>
                <tbody v-else-if="!this.loading && getTerminations.data.length">
                    <tr class="hover:bg-slate-100" v-for="item in getTerminations.data" :key="item.ID">
                        <td class="flex-row space-x-2">
                            <button class="bg-gray-600 rounded-full p-2" @click.prevent="$router.push({ name: 'incidences.create.termination', params: { recid: item.RecID, id: item.EmpID } })">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right text-white" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>
                            </button>
                        </td>
                        <td>{{ item.ID }}</td>
                        <td>{{ item.EmpID }}</td>
                        <td>{{ item.RequestedBy }}</td>
                        <td>{{ item.Location }}</td>
                        <td>{{ customFormatter(item.HireDate) }}</td>
                        <td>{{ item.LastDay }}</td>
                        <td>{{ terminationDate(item.TerminationDay) }}</td>
                        <td v-if="item.SignedResignation">
                            <a :href="getLink(item.File)" target="_blank" class="flex">
                                <div class="bg-gray-600 rounded-full p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf-fill text-white" viewBox="0 0 16 16">
                                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                    </svg>
                                </div>
                            </a>
                        </td>
                        <td v-else>
                            {{ item.Reason }}
                        </td>
                        <td>{{ item.Step }}</td>
                        <td class="text-center">
                            <span v-if="item.Status=='pending'" class="inline-block bg-gradient-to-b from-yellow-400 to-amber-600 text-white rounded-full px-3 py-1 leading-none text-sm text-bold">Pending</span>
                            <span v-else-if="item.Status=='cancelled'" class="inline-block bg-gradient-to-b from-red-400 to-red-600 text-white rounded-full px-3 py-1 leading-none text-sm text-bold">Cancelled</span>
                            <span v-else-if="item.Status=='completed'" class="inline-block bg-gradient-to-b from-green-400 to-green-600 text-white rounded-full px-3 py-1 leading-none text-sm text-bold">Completed</span>
                        </td>
                        <td>{{ item.UpdatedAt }}</td>
                    </tr>
                </tbody>
                <tbody class="divide-y" v-else-if="!this.loading && !getTerminations.data.length">
                    <tr class="hover:bg-slate-100">
                        <td colspan="12" class="text-center italic text-gray-400">Empty</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bg-slate-50 w-full h-[63.3px] shrink-0 content-center text-left px-9 rounded-b-[0.5rem] shadow-md">
            <TailwindPagination
                :data="getTerminations"
                @pagination-change-page="changePage"
                :limit="5"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment       from 'moment';
import TerminationsSearchBar from '@/views/employees/terminations/components/search-bar';
import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
// import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
export default {
    data() {
        return {
            filters: {},
            loading: true,
            reset_key: 0,
        }
    },
    components: {
        TailwindPagination,
        // TailwindPagination,
        TerminationsSearchBar,
    },
    computed: {
        ...mapGetters([
            'getTerminations',
            'getTerminationYears'
        ]),
    },
    methods: {
        ...mapActions([
            'fetchTerminations',
            'addTerminationComment',
            'fetchTerminationYears'
        ]),
        changePage(page = 1) {
            this.filters.page = page;
            this.changeFilters();
        },
        changeFilters(info = null) {
            this.loading = true;
            
            if ( info !== null ) {
                this.filters = info;
            }
            
            Promise.all([
                this.fetchTerminations(this.filters),
                this.fetchTerminationYears(),
            ]).then(() => {
                this.loading = false;
            });
        },
        getLink (name) {
            if(name?.startsWith("http")) return name;
            return 'https://centris-documents.website-us-southeast-1.linodeobjects.com/linode_resignations/' + name;
        },
        customFormatter(date){
            return moment(date).format("YYYY-MM-DD");
        },
        terminationDate(date){
            return date ?? 'Pending';
        },
    },
    created() {
        this.$emit('set-title', 'Terminations');
    }
}
</script>
<style scoped>
    .light-table th, .light-table td {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    
    .root-container {
        height: calc(100vh - 155px);
    }
</style>